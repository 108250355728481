import {
  CognitoUserAttribute,
  CognitoUserPool,
  ICognitoUserAttributeData,
  ICognitoUserPoolData,
} from "amazon-cognito-identity-js";

interface IData {
  idNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
}

export const signUp = async (
  data: IData,
  handleResult: CallableFunction
): Promise<void> => {
  try {
    const { idNumber, firstName, lastName, email, phone, password } = data;

    const userPassword = password
      ? password
      : import.meta.env.VITE_DEFAULT_PASSWORD;

    const poolData: ICognitoUserPoolData = {
      UserPoolId: import.meta.env.VITE_USER_POOL_ID,
      ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    };
    const userPool = new CognitoUserPool(poolData);
    const attributeList: CognitoUserAttribute[] = [];
    const dataEmail: ICognitoUserAttributeData = {
      Name: "email",
      Value: email,
    };
    const dataPhone: ICognitoUserAttributeData = {
      Name: "phone_number",
      Value: phone,
    };
    const dataName: ICognitoUserAttributeData = {
      Name: "name",
      Value: firstName,
    };
    const dataLastName: ICognitoUserAttributeData = {
      Name: "family_name",
      Value: lastName,
    };
    const dataUsername: ICognitoUserAttributeData = {
      Name: "preferred_username",
      Value: idNumber.trim(),
    };

    const attributeUsername = new CognitoUserAttribute(dataUsername);
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributePhone = new CognitoUserAttribute(dataPhone);
    const attributeName = new CognitoUserAttribute(dataName);
    const attributeLastName = new CognitoUserAttribute(dataLastName);

    attributeList.push(attributeUsername);
    attributeList.push(attributeEmail);
    attributeList.push(attributePhone);
    attributeList.push(attributeName);
    attributeList.push(attributeLastName);

    userPool.signUp(
      idNumber,
      userPassword,
      attributeList,
      [],
      async function (err, result) {
        if (err) {
          handleResult(err, undefined);
          Promise.resolve(err);
        } else {
          handleResult(undefined, { ...result, password });
          Promise.resolve({ ...result, password });
        }
      }
    );
  } catch (error) {
    Promise.reject(error);
  }
};
