import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";

export interface EmployeeInvitationModalProps {
  visible: boolean;
  employeeInvitationCode: string;
  setEmployeeHelperVisible: (boolean) => void;
}

function EmployeeInvitationModal({
  visible,
  employeeInvitationCode,
  setEmployeeHelperVisible,
}: EmployeeInvitationModalProps) {
  const navigate = useNavigate();

  return (
    <>
      <Modal open={visible} title={"Employee Invitation"} footer={null}>
        <span>
          Please either sign up or log in to accept an employment invitation.
        </span>
        <div style={{ textAlign: "center" }}>
          <br />
          <Button
            type="primary"
            className="btn-register"
            onClick={() => setEmployeeHelperVisible(false)}
          >
            Sign Up
          </Button>
          <br />
          <br />
          <Button
            type="primary"
            className="btn-register"
            onClick={() =>
              navigate(`/login/employee-invitation/${employeeInvitationCode}`)
            }
          >
            Log In
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default EmployeeInvitationModal;
