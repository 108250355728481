import { TASK_PAGE_SIZE } from "../constants";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface IFilters {
  tabKey?: undefined | string;
  filter?: {
    taskStatus?: false | string;
    taskId?: false | number;
    taskTypeId?: false | number;
    applicantType?: false | string;
    userId?: number | false;
    dateStart?: false | string | Date;
    dateEnd?: false | string | Date;
    sort?: string;
    searchTerm?: false | string;
    excludeIds?: number[];
  };
  savedCurrentPage?: number;
  savedSkip?: number;
}

interface IAppContext {
  filters?: IFilters;
  saveFilterObject?: (obj: Partial<IFilters["filter"]>) => void;
  saveFilter?: (key: keyof IFilters["filter"], val: any) => void;
  saveTabKey?: (tabKey: string) => void;
  savePageNumber?: (pageNo: number) => void;
  filterReset?: () => void;
  clearLocal?: () => void;
}

const AdminFilterCtx = createContext<IAppContext | null>(null);

export const initialValues: IFilters = {
  tabKey: "1",
  filter: {
    taskStatus: false,
    taskId: false,
    taskTypeId: false,
    applicantType: false,
    userId: false,
    dateStart: false,
    dateEnd: false,
    sort: "DESC",
    searchTerm: false,
  },
  savedCurrentPage: 1,
  savedSkip: 0,
};

type Props = {
  children: React.ReactNode;
};

export function AdminFilterContext({ children }: Props) {
  const [isLoading, setIsLoading] = useState(true);

  const getLocalStorageItems = (): IFilters | undefined => {
    const filterStorage = localStorage.getItem("taskFiltersHistory");

    if (filterStorage) {
      const { tabKey, filter, savedCurrentPage, savedSkip } =
        JSON.parse(filterStorage);

      return { tabKey, filter, savedCurrentPage, savedSkip };
    }

    return undefined;
  };

  const setLocalStorageItems = (arg: IFilters): void => {
    localStorage.setItem("taskFiltersHistory", JSON.stringify(arg));
  };

  const getSavedData = useCallback(() => {
    const local = getLocalStorageItems();
    if (local) {
      const { tabKey, filter, savedCurrentPage, savedSkip } = local;
      return {
        ...initialValues,
        tabKey,
        filter: { ...initialValues.filter, ...filter },
        savedCurrentPage,
        savedSkip,
      };
    }

    return initialValues;
  }, []);

  const [filters, setFilters] = useState<IFilters>(initialValues);

  function saveFilterObject(obj: Partial<IFilters["filter"]>) {
    if (!obj) return;

    setLocalStorageItems({
      ...filters,
      savedCurrentPage: 1,
      savedSkip: 0,
      filter: { ...filters.filter, ...obj },
    });

    setFilters((prevState) => {
      const newState = {
        ...prevState,
        savedCurrentPage: 1,
        savedSkip: 0,
        filter: { ...prevState.filter, ...obj },
      };

      return newState;
    });
  }

  const saveFilter = (key: keyof IFilters["filter"], val: any) => {
    if (!key || !val) return;

    setLocalStorageItems({
      ...filters,
      savedCurrentPage: 1,
      savedSkip: 0,
      filter: { ...filters.filter, [key]: val },
    });

    setFilters((prevState) => {
      const newState = {
        ...prevState,
        savedCurrentPage: 1,
        savedSkip: 0,
        filter: { ...prevState.filter, [key]: val },
      };
      return newState;
    });
  };

  const saveTabKey = useCallback((tabKey: string) => {
    if (!tabKey) return;

    setLocalStorageItems({ tabKey });
    setFilters(() => ({
      ...initialValues,
      tabKey,
    }));
  }, []);

  const savePageNumber = (pageNo: number = 1) => {
    const filterStorage = localStorage.getItem("taskFiltersHistory");

    const { filter, tabKey } = filterStorage
      ? JSON.parse(filterStorage)
      : initialValues;

    const toSkip = (pageNo - 1) * TASK_PAGE_SIZE;

    setLocalStorageItems({
      tabKey,
      filter,
      savedCurrentPage: pageNo,
      savedSkip: toSkip,
    });

    setFilters((prevState) => {
      return {
        ...prevState,
        savedCurrentPage: pageNo,
        savedSkip: toSkip,
      };
    });
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  // reset filters
  const filterReset = async () => {
    setIsLoading(true);

    setLocalStorageItems({ tabKey: filters.tabKey });

    setFilters((prevState) => ({
      ...initialValues,
      tabKey: prevState.tabKey,
    }));

    await delay(0.5);
    setIsLoading(false);
  };

  const clearLocal = () => {
    localStorage.removeItem("taskFiltersHistory");
  };

  useEffect(() => {
    setIsLoading(true);
    setFilters(getSavedData());
    setIsLoading(false);
  }, []);

  return (
    <AdminFilterCtx.Provider
      value={{
        filters,
        saveTabKey,
        saveFilter,
        saveFilterObject,
        savePageNumber,
        filterReset,
        clearLocal,
      }}
    >
      {!isLoading && children}
    </AdminFilterCtx.Provider>
  );
}

export const useFilterState = () => useContext(AdminFilterCtx);
