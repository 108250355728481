import { Link } from "react-router-dom";

import {
  CreditCardOutlined,
  ExclamationCircleFilled,
  FileTextOutlined,
  FolderOpenOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Menu } from "antd";
import { DEREGISTERED_COMPANY } from "../../constants";
import { useGlobalState } from "../../globalStore";

function MemberNavigation({ roles, permissions, roleStream }) {
  const { state } = useGlobalState();
  const userRole = localStorage.getItem("userRoles");
  const companyStatus = localStorage.getItem("companyStatus");

  const isEmployee = userRole === "Employee";
  const disabled = companyStatus === DEREGISTERED_COMPANY;

  return (
    <Menu
      theme="light"
      defaultSelectedKeys={["/profile"]}
      selectedKeys={[window.location.pathname]}
      mode="inline"
    >
      <Menu.Item key="/profile" disabled={disabled}>
        <Link to="/profile">
          <UserOutlined />
          <span>Profile</span>
        </Link>
      </Menu.Item>
      {!isEmployee && (
        <Menu.Item key="/business-profile" disabled={disabled}>
          <Link to="/business-profile">
            <UserOutlined />
            <span>Business Profile</span>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="/tasks" disabled={disabled}>
        <Link to="/tasks">
          <FileTextOutlined />
          <span>Tasks</span>
          {(state?.lookups?.registrationCount ?? 0) > 0 && (
            <ExclamationCircleFilled
              style={{
                color: "red",
                fontSize: "15px",
                marginLeft: "10px",
              }} />
          )}
        </Link>
      </Menu.Item>
      <Menu.Item key="/transactions" disabled={disabled}>
        <Link to="/transactions">
          <CreditCardOutlined />
          <span>Transactions</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/documents">
        <Link to="/documents">
          <FolderOpenOutlined />
          <span>Documents</span>
        </Link>
      </Menu.Item>
      {!isEmployee && (
        <Menu.Item key="/employees">
          <Link to="/employees">
            <TeamOutlined />
            <span>Team</span>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
}

export default MemberNavigation;
