import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";

interface RegistrationState {
  batchRegistrationId?: number;
  manualRegistrationApplicationId?: number;
  manualRegisterId?: number;
  applicationId?: number;
  isTransfer?: boolean;
  loading?: boolean;
  previousCompanyId?: number;
  manualRegistrationType?: string;
  manualRegisterUser?: any;
  registeringAs?: string;
  isTransferable?: boolean;
  isExistingUser?: boolean;
  isMultipleEmployment?: boolean;
  manualRegisterCompanyId?: number;
  step1Saved?: boolean;
  step2Saved?: boolean;
  step3Saved?: boolean;
  step4Saved?: boolean;
  step5Saved?: boolean;
  step6Saved?: boolean;
  step7Saved?: boolean;
  step8Saved?: boolean;
  step9Saved?: boolean;
  registrationCapacity?: string;
  registrationType?: string;
  appliedForExemption?: boolean;
  cipcDocumentUploaded?: boolean;
  currentStep?: number;
  capacityId?: number;
  companyId?: number;
  companyName?: string;
  tradingName?: string;
  paymentTaskId?: number;
  regFees?: number;
  employmentId?: number;
  directDepositTransactionId?: number;
  owner?: number;
  completeBatchApplicationId?: number;
  setRegistrationState: (key: keyof RegistrationState, value: any) => void;
  setRegistrationStateObject: (obj: Partial<RegistrationState>) => void;
  clearRegistrationState: (incBatchId?: boolean) => void;
}

export const useRegistrationState = create<RegistrationState>()(
  devtools(
    persist(
      (set) => ({
        currentStep: 0,
        loading: false,
        batchRegistrationId: undefined,
        setRegistrationState: (key, value) => set({ [key]: value }),
        setRegistrationStateObject: (obj: Partial<RegistrationState>) => {
          set((state) => ({ ...state, ...obj }));
        },
        clearRegistrationState: (incBatchId = false) => {
          const empty: Partial<RegistrationState> = {
            applicationId: undefined,
            isTransfer: undefined,
            previousCompanyId: undefined,
            loading: false,
            manualRegistrationType: undefined,
            manualRegisterUser: undefined,
            registeringAs: undefined,
            isTransferable: undefined,
            isExistingUser: undefined,
            isMultipleEmployment: undefined,
            manualRegisterCompanyId: undefined,
            step1Saved: undefined,
            step2Saved: undefined,
            step3Saved: undefined,
            step4Saved: undefined,
            step5Saved: undefined,
            step6Saved: undefined,
            step7Saved: undefined,
            step8Saved: undefined,
            step9Saved: undefined,
            registrationCapacity: undefined,
            registrationType: undefined,
            appliedForExemption: undefined,
            manualRegistrationApplicationId: undefined,
            currentStep: 0,
            cipcDocumentUploaded: undefined,
            manualRegisterId: undefined,
            capacityId: undefined,
            companyId: undefined,
            companyName: undefined,
            paymentTaskId: undefined,
            directDepositTransactionId: undefined,
            regFees: undefined,
            tradingName: undefined,
            employmentId: undefined,
            owner: undefined,
          };

          if (incBatchId) {
            empty.batchRegistrationId = undefined;
          }

          set(empty);
        },
      }),
      {
        name: "registration-storage",
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
