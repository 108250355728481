import * as AWSCognito from "amazon-cognito-identity-js";

export const signOut = async (data: any): Promise<any> => {
  try {
    const { email, idNumber } = data;

    const poolData: AWSCognito.ICognitoUserPoolData = {
      UserPoolId: import.meta.env.VITE_USER_POOL_ID,
      ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    };
    const userPool = new AWSCognito.CognitoUserPool(poolData);
    const userData = {
      Username: idNumber ? idNumber : email,
      Pool: userPool,
    };
    const cognitoUser = new AWSCognito.CognitoUser(userData);
    cognitoUser.signOut();

    localStorage.clear();
    window.location.replace("/login");

    Promise.resolve();
  } catch (error) {
    Promise.reject(error);
  }
};
