export function hasPermission(action: string, permissions?: string): boolean {
  const _permissionString =
    permissions || localStorage.getItem("userPermissions");

  let _permissions: string[] = [];

  if (_permissionString) {
    _permissions = _permissionString.split(",");
  }

  const canPerform = _permissions.includes(action) ?? false;

  if (!canPerform) console.warn("user_cant_perform_this_action", action);

  return canPerform;
}

type Props = {
  yes?: () => any;
  no?: () => any;
  perform: string;
  permissions?: string;
};

export default function Can(props: Props) {
  const yes = props.yes ?? (() => null);
  const no = props.yes ?? (() => null);
  return hasPermission(props.perform, props.permissions) ? yes() : no();
}
