import * as AWSCognito from "amazon-cognito-identity-js";
import { ID_TOKEN_NAME } from "../../constants";

export const authenticateUser = async (
  data: any,
  handleResult: CallableFunction
): Promise<any> => {
  const { idNumber, email, password } = data;

  const poolData: AWSCognito.ICognitoUserPoolData = {
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  };
  const userPool = new AWSCognito.CognitoUserPool(poolData);

  const authenticationData = {
    Username: idNumber || email,
    Password: password,
  };

  const authenticationDetails = new AWSCognito.AuthenticationDetails(
    authenticationData
  );

  const userData = {
    Username: idNumber ? idNumber : email,
    Pool: userPool,
  };

  const cognitoUser = new AWSCognito.CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (result) {
      localStorage.setItem(ID_TOKEN_NAME, result.getIdToken().getJwtToken());
      localStorage.setItem("isAuthenticated", "true");
      handleResult(undefined, result, cognitoUser);
      Promise.resolve(result);
    },

    onFailure: function (err) {
      console.log(err);
      handleResult(err, undefined);
      Promise.resolve(err);
    },
    mfaRequired: function (codeDeliveryDetails) {
      var verificationCode = prompt("Please input verification code", "");
      cognitoUser.sendMFACode(verificationCode, this);
    },
  });
};
