import { Modal } from "antd";

export default function SignupSuccessModal({
  visible,
  onCancel,
  actionText,
  modalTitle,
  onAction,
}) {
  return (
    <Modal
      open={visible}
      title={modalTitle}
      okText={actionText}
      onCancel={onCancel}
      onOk={onAction}
      okButtonProps={{
        className: "purple-button",
      }}
      okType="default"
    >
      {" "}
      <div>
        <br />
        <p>
          You have successfully registered on the Council of Debt Collectors. A
          verification email has been sent to your registered email address with
          further instructions. Alternatively, you can visit{" "}
          <a href="https://www.cfdc.org.za/ ">CFDC Website</a> if you need more
          information.
        </p>
        <br />
      </div>
    </Modal>
  );
}
