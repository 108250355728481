import { Link } from "react-router-dom";

import {
  CreditCardOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Menu } from "antd";

function Navigation({ roles, permissions, roleStream }) {
  return (
    <Menu
      theme="light"
      defaultSelectedKeys={["/admin/profile"]}
      selectedKeys={[window.location.pathname]}
      mode="inline"
    >
      <Menu.Item key="/admin/profile">
        <Link to="/admin/profile">
          <UserOutlined />
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/tasks">
        <Link to="/admin/tasks">
          <FileTextOutlined />
          <span>Tasks</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/members">
        <Link to="/admin/members">
          <CreditCardOutlined />
          <span>Members</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/companies">
        <Link to="/admin/companies">
          <FolderOpenOutlined />
          <span>Companies</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/transactions">
        <Link to="/admin/transactions">
          <CreditCardOutlined />
          <span>Transactions</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/team">
        <Link to="/admin/team">
          <TeamOutlined />
          <span>Team</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
}

export default Navigation;
