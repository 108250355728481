import { Button, Input, Form } from "antd";
import { SIGNUP_MUTATION } from "@/graphql/mutations";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { signUp } from "@/utils/auth/createUser";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import cfdcLogo from "../../assets/images/cfdc-logo.png";
import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE, TERMS } from "../../constants";
import { authenticateUser } from "@/utils/auth/authenticateUser";
import SignupSuccessModal from "./signupSuccessModal";

interface IProps {
  employeeInvitationCode?: string;
}

function RegisterForm({ employeeInvitationCode }: IProps) {
  const [loading, setLoading] = useState(false);
  const [createUser] = useMutation(SIGNUP_MUTATION);
  const [showModal, setShowModal] = useState(false);

  const [form] = Form.useForm();

  const RemoveUserModalProps = {
    visible: showModal,
    modalTitle: "Successfully Signed Up!",
    actionText: "Ok",
    onCancel: () => handleModal(),
    onAction: () => handleModal(),
  };

  const handleModal = () => {
    setShowModal(false);
    window.location.href = "https://www.cfdc.org.za/";
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    console.log(values);

    try {
      delete values.confirm_password;
      if (
        values.phone.substring(0, 3) == "027" ||
        values.phone.substring(0, 3) == "+27"
      ) {
        values.phone = "+27" + values.phone.slice(3);
      } else if (values.phone.substring(0, 1) == "0") {
        values.phone = "+27" + values.phone.slice(1);
      } else if (values.phone.substring(0, 3) != "+27") {
        values.phone = "+27" + values.phone;
      }
      return signUp(values, async (err, result) => {
        if (err) {
          switch (err.code) {
            default:
              notifyError(
                `${err.message} Hint: prefix with country dialling code e.g +27`
              );
              break;
          }
          setLoading(false);
        } else {
          //authenticate user to create a session
          await authenticateUser(
            {
              idNumber: values.idNumber,
              email: values.email,
              password: values.password,
            },
            async (err, _) => {
              if (err) {
                return openNotificationWithIcon(
                  "error",
                  "Sign up Error",
                  err.message
                );
              }
              // login success
              // create user in db
              const { data }: any = await createUser({
                variables: {
                  input: {
                    ...values,
                    sendEmailVerificationToken: true,
                  },
                },
              });

              if (data.createUser && data.createUser.id) {
                setLoading(false);
                form.resetFields();
                openNotificationWithIcon(
                  "success",
                  "Registration Success",
                  "You have successfully created an account, please check your email for details"
                );
                localStorage.clear();
                setShowModal(true);
              }
            }
          );
        }
      });
    } catch (error) {
      setLoading(false);
      return notifyError(error);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={(error) => {
          console.log(error);
        }}
        className="register-form"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ textAlign: "center" }}>
            <img src={cfdcLogo} alt="cfdc" className="logo-register" />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "2vh",
              marginBottom: "1vh",
            }}
          >
            <h3>Sign Up</h3>
          </div>
        </div>
        <Form.Item
          label="ID/Passport Number"
          className="form-item"
          hasFeedback
          name="idNumber"
          required
        >
          <Input
            placeholder="Please enter either your ID or passport #"
            readOnly={loading}
          />
        </Form.Item>

        <Form.Item
          label="First Name"
          className="form-item"
          hasFeedback
          name="firstName"
          normalize={(s) => s?.toUpperCase()}
          required
        >
          <Input placeholder="Please enter your name" readOnly={loading} />
        </Form.Item>

        <Form.Item
          label="Last Name"
          className="form-item"
          hasFeedback
          normalize={(s) => s?.toUpperCase()}
          required
          name="lastName"
        >
          <Input placeholder="Please enter your name" readOnly={loading} />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          className="form-item"
          hasFeedback
          name="phone"
          required
        >
          <Input
            addonBefore={
              <div>
                <span style={{ color: "black" }}>🇿🇦</span> +27
              </div>
            }
            placeholder="Please enter your phone"
            readOnly={loading}
          />
        </Form.Item>

        <Form.Item
          label="Email"
          className="form-item"
          hasFeedback
          name={"email"}
          normalize={(s) => s?.toUpperCase()}
          rules={[
            { required: true, message: "Please input your email!" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input placeholder="Please enter your email" readOnly={loading} />
        </Form.Item>

        <Form.Item
          label="Password"
          className="form-item"
          hasFeedback
          name={"password"}
          rules={[
            { required: true, message: "Please input your Password!" },
            {
              pattern: PASSWORD_REGEX,
              message: PASSWORD_REGEX_MESSAGE,
            },
            {
              min: 8,
              message: "Password has to be longer than 8 characters!",
            },
          ]}
        >
          <Input.Password
            placeholder="Please enter password here"
            readOnly={loading}
          />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          className="form-item"
          hasFeedback
          dependencies={["password"]}
          name={"confirm_password"}
          rules={[
            { required: true, message: "Please re-enter your password" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Please re-enter your password"
            readOnly={loading}
          />
        </Form.Item>
        <div style={{ marginBottom: "30px", padding: "0 40px" }}>
          <h3 style={{ textAlign: "center" }}>
            By signing up for registration I agree to the following:
          </h3>
          <ol style={{ margin: "1vh", fontSize: "13px" }}>
            {TERMS.map((term, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                {term}
              </li>
            ))}
          </ol>
        </div>

        <Button
          htmlType="submit"
          type="primary"
          className="btn-register"
          loading={loading}
        >
          Sign Up
        </Button>

        <span>Already have an account?</span>

        <Link
          to={
            employeeInvitationCode
              ? `/login/employee-invitation/${employeeInvitationCode}`
              : "/"
          }
        >
          <h4>Sign In</h4>
        </Link>
      </Form>
      <SignupSuccessModal {...RemoveUserModalProps} />
    </>
  );
}

export default RegisterForm;
