import { Link } from "react-router-dom";

import {
  AppstoreOutlined,
  BarChartOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Menu } from "antd";
import PermissionsGuard from "../../components/Auth/can";

function SuperUserNavigation({ roles, permissions, roleStream }) {
  const { SubMenu } = Menu;

  return (
    <Menu
      theme="light"
      defaultSelectedKeys={["/"]}
      selectedKeys={[window.location.pathname]}
      mode="inline"
    >
      <Menu.Item key="/admin/dashboard">
        <Link to="/admin/dashboard">
          <AppstoreOutlined />
          <span>Dashboard</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/admin/profile">
        <Link to="/admin/profile">
          <UserOutlined />
          <span>Profile</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/admin/tasks">
        <Link to="/admin/tasks">
          <FileTextOutlined />
          <span>Tasks</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/transactions">
        <Link to="/admin/transactions">
          <CreditCardOutlined />
          <span>Transactions</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/documents">
        <Link to="/admin/documents">
          <FolderOpenOutlined />
          <span>Documents</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/team">
        <Link to="/admin/team">
          <TeamOutlined />
          <span>Team</span>
        </Link>
      </Menu.Item>
      <PermissionsGuard
        permissions={permissions}
        perform={"admin:view"}
        yes={() => (
          <Menu
            theme="light"
            selectedKeys={[window.location.pathname]}
            mode="inline"
          >
            <SubMenu
              key="sub1"
              title={
                <span>
                  <UserOutlined />
                  <span>Admin Portal</span>
                </span>
              }
            >
              <SubMenu key="sub2" title={<span>User Management</span>}>
                <Menu.Item key="/admin/members">
                  <Link to="/admin/members">Users</Link>{" "}
                </Menu.Item>
                <Menu.Item key="/admin/roles">
                  <Link to="/admin/roles">Roles</Link>{" "}
                </Menu.Item>
                <Menu.Item key="/admin/permissions">
                  <Link to="/admin/permissions">Permissions</Link>{" "}
                </Menu.Item>
              </SubMenu>
              {/* <Menu.Item key="/admin/businessunits">
                          <Link to="/admin/businessunits">Business Units</Link>{" "}
                        </Menu.Item> */}
              <Menu.Item key="/admin/companies">
                <Link to="/admin/companies">Companies</Link>
              </Menu.Item>
              <Menu.Item key="/admin/blacklist">
                <Link to="/admin/blacklist?tab=company">BlackList</Link>
              </Menu.Item>
              <Menu.Item key="/admin/withdrawn-employment">
                <Link to="/admin/withdrawn-employment?pageNumber=1">
                  Withdrawn Employment
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/pending-applications">
                <Link to="/admin/pending-applications?pageNumber=1">
                  Pending Applications
                </Link>
              </Menu.Item>
              <Menu.Item key="/admin/background-jobs">
                <Link to="/admin/background-jobs">Background Jobs</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        )}
      />
      <Menu.Item key="/admin/reports">
        <Link to="/admin/reports">
          <BarChartOutlined />
          <span>Reports</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
}

export default SuperUserNavigation;
